/* src/styles/Login.css */

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--color-background);
  font-family: var(--font-main);
  padding: 2rem 1rem;
  overflow-y: auto; /* Allow vertical scrolling */
}

.login-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  margin: 2rem 0; /* Add some margin to top and bottom */
}

.login-title {
  color: var(--color-text);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  color: var(--color-text);
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--color-tertiary);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--color-primary);
}

.radio-group {
  margin-bottom: 0.5rem;
}

.radio-input {
  margin-right: 0.5rem;
}

.radio-label {
  font-size: 0.9rem;
  color: var(--color-text);
}

.checkbox-label {
  font-size: 0.9rem;
  color: var(--color-text);
  margin-left: 3px;

}
.submit-button {
  width: 100%;
  background-color: var(--color-primary);
  color: black;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: 600;
}

.submit-button:hover {
  background-color: #5bc1a8;
}

.error-alert {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  color: #b71c1c;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.register-link {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: var(--color-text);
}

.register-link-text {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.register-link-text:hover {
  color: var(--color-secondary);
  text-decoration: underline;
}

@media screen and (max-height: 800px) {
  .login-container {
    align-items: flex-start; /* Align to top when screen height is small */
  }
}

/* Override the main.css styles for the login page */
html, body {
  height: auto;
  overflow: auto;
}

#root, .App {
  height: auto;
  min-height: 100vh;
}
