/* main.css */
@import url('https://fonts.googleapis.com/css2?family=Helvetica:wght@300;400;600;700&display=swap');

:root {
  --color-background: #f0f8f6;
  --color-text: #333333;
  --color-primary: #77E5C8;
  --color-secondary: #5bc0a8;
  --color-tertiary: #a9a9a9;
  --color-feature-bg: #ffffff;
  --color-feature-icon: #4db6ac;
  --color-footer-bg: #333333;
  --color-footer-text: #ffffff;
  --font-main: 'Helvetica', sans-serif;
  --font-chat: 'Helvetica', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-main);
  line-height: 1.6;
}

#root, .App {
  height: 100%;
}

.btn {
  background-color: var(--color-primary);
  color: black;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.btn:hover {
  background-color: #5bc1a8;
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--color-secondary);
}

::-webkit-scrollbar-thumb {
  background: var(--color-tertiary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}
