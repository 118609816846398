
/* home.css */
.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.home-content {
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
}

.hero {
  text-align: center;
  padding: 2rem 0;
}

.hero h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.features {
  padding: 2rem 0;
}

.features h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.feature-item {
  text-align: center;
  padding: 2rem;
  background-color: var(--color-feature-bg);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-item svg {
  font-size: 3rem;
  color: var(--color-feature-icon);
  margin-bottom: 1rem;
}

.feature-item h3 {
    color: #000000;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-shadow: 2px 0px 15px var(--color-primary);
}

footer {
  text-align: center;
  padding: 1.5rem 0;
  background-color: var(--color-footer-bg);
  color: var(--color-footer-text);
}


@media (max-width: 768px) {
  .hero h2 {
    font-size: 36px;
    margin-bottom: 1rem;
    line-height: 45px;
  }
}
  
