/* chat.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  --color-background: #DBDBDB;
  --color-secondary: #a9a9a9;
  --color-tertiary: #a9a9a9;
  --color-text: #1a1a1a;
  --color-primary: #77E5C8;
  --color-input: #c0c0c0;
}

.chat-layout {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Prevent overflow on the main container */

  background-color: var(--color-background);
}

.sidebar {
  width: 100%; /* Adjust width to 100% for small screens */
  min-width: 0; /* Remove min-width to allow shrinking */
  background-color: var(--color-primary);
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure the footer is at the bottom */
}


.sidebar-footer {
  font-size: 10px;
  font-family: Helvetica, Arial, sans-serif;
  color: #888888;
  text-align: center;
  margin-top: auto; /* Push the footer to the bottom */
  padding-top: 1rem; /* Add some space above the footer */
}

.chat-area {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  overflow-x: hidden;
  position: relative; /* Position relative for absolute elements if needed */

}
.chat-messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 15px; /* Add some padding for the scrollbar */
}
/* Customize the scrollbar */
.chat-messages-container::-webkit-scrollbar {
  width: 8px;
  margin-right: 0%;
}

.chat-messages-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-messages-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.chat-messages-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.chat-header {
  display: flex;
  justify-content:right;
  align-items:flex-end;
  padding: .5rem;
  color: var(--color-text);
}
.logout-button {
  padding: 0.5rem 1rem;
  background-color: var(--color-background);
  color: var(--color-text);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border: 1px solid var(--color-primary); /* Add border with slightly darker color */
}

.logout-button:hover {
  background-color: var(--color-secondary);
  border-color: rgba(0, 0, 0, 0.4); /* Darken border color on hover */
}

.logout-button:hover {
  background-color: var(--color-secondary);
}

.desktop-only {
  display: none;
}

.mobile-only {
  display: block;
}

@media (min-width: 768px) {
  .desktop-only {
    display: flex;
  }

  .mobile-only {
    display: none;
  }

  .chat-area {
    padding-top: 0; /* Remove top padding as we now have a header */
  }

  .chat-messages-container {
    height: calc(100% - 60px); /* Adjust for header height */
  }
}
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

.chat-message {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  animation: fadeIn 0.3s ease-out;
}

.bot-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  max-width: 100%;
}

.bot-message-content {
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
}

.robot-icon-container {
  width: 40px;
  height: 40px;
  /* background-color: var(--color-primary); */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
}

.robot-icon {
  font-size: 1.5rem;
  color: white;
}

.bot-text {
  flex-grow: 1;
  color: var(--color-text);
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-top: 1rem;

}

.feedback-icons {
  margin-top: 10px; /* Ensure the feedback icons are below the bot's message */
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.chat-input-container {
  position: relative;
  bottom: 20px;
  width: 80%;
  margin-left: 10%;
  align-self: self-start;
  background-color: var(--color-input);
  border-radius: 50px;
  z-index: 10;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 50px;
  background-color: var(--color-input);
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.chat-input:focus-within {
  border-color: var(--color-primary);
  outline: none;
}

.chat-input textarea {
  flex-grow: 1;
  padding: 0.8rem;
  background-color: transparent;
  color: var(--color-text);
  font-family: var(--font-chat);
  font-size: 0.9rem;
  border: none;
  outline: none;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

.chat-input input::placeholder {
  color: #888888;
}

.chat-input button,
.attachment-button,
.send-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.8rem 1rem;
  color: var(--color-text);
  font-size: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.chat-input button {
  background-color: var(--color-primary);
  color: black;
  border-radius: 50%;
}

.chat-input button:hover,
.attachment-button:hover,
.send-button:hover {
  color: var(--color-primary);
}

.chat-input button:hover {
  background-color: #5bc1a8;
}

.chat-item {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  color: var(--color-text);
}

.chat-item:hover {
  background-color: var(--color-tertiary);
}

.message-bubble {
  background-color: white;
  color: var(--color-text);
  border-radius: 12px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  max-width: 50%;
  width: fit-content;
  align-self: flex-start;
}

.user .message-bubble {
  background-color: white;
  color: var(--color-text);
  align-self: flex-end;
}

.message-bubble p {
  line-height: 1.2;
}

.home-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: black;
  width: 47px;
  height: 51px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.home-icon:hover {
  background-color: #5bc1a8;
}

.markdown p {
  line-height: 1.2;
}


.markdown ul {
  line-height: .4;
  margin-bottom: 0;
  margin-top:  0;
}

.markdown li {
  line-height: .9;
  margin-bottom: 0;
  margin-top: 0;
}
.markdown ol {
  line-height: .4;
  margin-bottom: 0;
  margin-top:  0;
}



@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes loading {
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
}

.loading-bubble {
  display: flex;
  gap: 4px;
}

.loading-dot {
  width: 8px;
  height: 8px;
  background-color: var(--color-primary);
  border-radius: 50%;
  animation: loading 1s infinite;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* Feedback styles */
.feedback-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feedback-popup {
  background: #1e1e1e;
  color: #ccc;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.feedback-popup h2 {
  text-align: center;
  color: #ffd700;
}

.feedback-popup form {
  display: flex;
  flex-direction: column;
}

.feedback-popup label {
  margin-bottom: 10px;
  color: #ccc;
}

.feedback-popup input[type="text"],
.feedback-popup input[type="checkbox"] {
  padding: 8px;
  margin-top: 5px;
  margin-right: 5px;
  border: 1px solid #333;
  background: #2d2d2d;
  color: #fff;
  border-radius: 4px;
}

.feedback-popup button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffd700;
  color: #1e1e1e;
  transition: background-color 0.3s ease;
}

.feedback-popup button:hover {
  background-color: #e6c200;
}

.feedback-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.feedback-icon svg {
  fill: transparent;
  stroke: #999;
  transition: fill 0.3s ease, stroke 0.3s ease;
}

.feedback-icon:hover svg {
  /* fill: #666; */
  stroke: #666;
}

.feedback-icon.thumbs-up-button.active svg {
  /* fill: #4CAF50; */
  stroke: #4CAF50;
}

.feedback-icon.thumbs-down-button.active svg {
  /* fill: #F44336; */
  stroke: #F44336;
}

/* Hide the file input visually */
.file-upload-input {
  display: none;
}

.chat-disclaimer {
  font-size: 0.85rem;
  color: #888888;
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
}


/* Media Queries */
@media (min-width: 768px) {
  .chat-layout {
    flex-direction: row;
  }

  .header-mobile {
    display: none;
  }

  .sidebar {
    width: 300px;
    min-width: 300px;
    height: 100%;
  }

  .sidebar-icon_br {
    margin-left: 12%;
  }

  .sidebar-text {
    width: 100%;
    height: 70%;
  }

  .sidebar-text_paragraphs {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    margin-top: 25%;
    font-family: 'Roboto Mono';
    font-size: 13px;
    color: #595959;
  }

  .sidebar-text_paragraphs div p {
    font-weight: bold;
  }

  .week {
    text-decoration: underline;
  }

  .chat-area {
    padding: 0 0%;
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  .sidebar-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .chat-area {
    padding: 0 2%;
    height: 88%;
  }
  .chat-messages-container {
    padding: 0rem;
  }

  .chat-container {
    height: 100vh;
  }
  
  .chat-disclaimer {
    display: none;
  }

  .chat-input {
    padding: 0;
  }


  .chat-layout {
    height: 100vh;
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    flex-shrink: 1;
    display: none;
  }

  .home-icon {
    position: fixed;
    background-color: var(--color-background);
    top: 0.5rem;
  }

  .header-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .bot-message-content {
    padding: 0;
    margin-bottom: 0;
  }

  .chat-message {
    margin-bottom: 0;
  }

  .message-bubble {
    margin-bottom: 0;
  }

  .feedback-icons {
    margin-top: 0;
  }

  .sidebar-footer {
    padding-top: 2%;
  }

  .sidebar-text{
    display: none;
  }

  .message-bubble {
    max-width: 80%; 
  }
} 